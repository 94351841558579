<template>
    <div class="bottom-padding">
        <div class="pb-10 h-100 ml-2 mr-2">

            <div class="start-overflow">
                <div class="create-company-container w-100 ">
                    <div v-if="basicInfo" class="main-div">
                        <div class="create-company-header d-flex align-items-center justify-content-between">
                            <span class="fs-14 ml-3 pl-3 text-white">Basic Information</span>
                            <span class="pointer" @click="showBasicInformation">
                                <i v-if="basicInformationTab" class="icon-chevron-double-up text-white"
                                    style="font-size: 20px"></i>
                                <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
                            </span>
                        </div>
                    </div>
                    <div :class="{'d-none':!basicInformationTab}" class="px-10" >
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Organization<span class="pl-1 text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2">
                                        <multiselect  class="diginew-multiselect filterName" v-validate="'required'" @search-change="getOrganisationOptions"
                                            name="organisation_type" label="organization_name" :show-labels="false" :searchable="true" :loading="organisation_loading" @open="getOrganisationOptions" :options="organisation_type"
                                            v-model="organisation"  @select="orgChange()" placeholder="Select Organization">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Organization are not found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <span class="float-left text-danger mb-3" v-show="errors.has('organisation_type')">Organisation is required</span>
                                </div>
                            </div>  
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Company to link<span class="pl-1 text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2" :class="{'pointer-disable':organisation ==''}">
                                        <multiselect  class="diginew-multiselect filterName" v-validate="'required'"  :disabled="organisation ==''" @search-change="getLinkEmpOptions"
                                            name="link_emp_type" :searchable="true" :options="link_emp_type"
                                            v-model="link_emp" label="company_name" :show-labels="false" @open="getLinkEmpOptions" :loading="link_emp_loading" placeholder="Select Company to link" @select="dataChange()">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600" selected>Company to links are not
                                                    Found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <span class=" text-danger" v-show="errors.has('link_emp_type')" style="text-transform: none !important;">Company to link is required</span>
                                </div>
                            </div>
                             <div class="col-lg-4 col-md-6 col-sm-12 mt-6" >
                                <div class="w-100" >
                                    <span class="input-label">Subsidiary Employee<span class="pl-1 text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2" :class="{'pointer-disable':link_emp =='' && organisation =='' || link_emp =='' && organisation !=''}" >
                                        <multiselect @search-change="getSubsidiaryEmpOptions" class="diginew-multiselect filterName" v-validate="'required'" :disabled="link_emp =='' && organisation =='' || link_emp =='' && organisation !='' "
                                            name="subsidiary_emp_type" label="name"  :show-labels="false" :searchable="true" :loading="subsidiary_emp_loading" @open="getSubsidiaryEmpOptions" :options="subsidiary_emp_type"
                                            v-model="subsidiary" placeholder="Select Subsidiary Employee" >
                                            <template slot="option" slot-scope="props">
                                                <div class="d-flex align-items-center">
                                                    <div class="option__email d-flex flex-column ml-2">
                                                        <span class="option__title">{{ props.option.name }} ({{ props.option.company_name}})</span>
                                                        <!-- <span class="option__small" style="display: none;">{{ props.option.email}}</span> -->
                                                    </div>
                                                </div>
                                            </template>
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Subsidiary Employees are not found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <span class="float-left text-danger mb-3" v-show="errors.has('subsidiary_emp_type')">Subsidiary Employee is required</span>
                                </div>
                            </div>  
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Business Unit<span class="pl-1 text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2" :class="{'pointer-disable':link_emp ==''}">
                                        <multiselect  @search-change="getBusinessUnitOptions" class="diginew-multiselect filterName" v-validate="'required'" @select="deptChange"
                                            name="business_units_type" :searchable="true" :options="business_units_type" :disabled="link_emp ==''"
                                            v-model="business_units" label="name" :show-labels="false" @open="getBusinessUnitOptions" :loading="business_unit_loading" placeholder="Select Business Units">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600" selected>Bussiness units are not
                                                    Found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <span class=" text-danger" v-show="errors.has('business_units_type')" style="text-transform: none !important;">Business Units
                                        are required</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Department<span class="pl-1 text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2" :class="{'pointer-disable':link_emp =='' && business_units =='' || link_emp !=''&& business_units ==''}">
                                        <multiselect   @search-change="getDepartmentUnitOptions" class="diginew-multiselect filterName" v-validate="'required'" :disabled="link_emp =='' && business_units =='' || link_emp !=''&& business_units ==''"
                                            name="department_type" label="name" :show-labels="false" :searchable="true" :loading="department_unit_loading" @open="getDepartmentUnitOptions" :options="department_type"
                                            v-model="department" placeholder="Select Department">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Departments are not found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>

                                </div>
                                <span class=" text-danger mb-3" v-show="errors.has('department_type')" style="text-transform: none !important;">Department is
                                required</span>
                            </div>
                             <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <span class="input-label">Branch<span class="pl-1 text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2" :class="{'pointer-disable':link_emp ==''}">
                                        <multiselect class="diginew-multiselect filterName" v-validate="'required'" 
                                            name="branch_type_options" @open="getBranchOptions" :loading="branch_loading" :searchable="true" :disabled="link_emp ==''"
                                            :options="branch_type_options" label="name" :show-labels="false" v-model="branch" placeholder="Select Branch" >
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Branch is not found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <span class=" text-danger" v-show="errors.has('branch_type_options')" style="text-transform: none !important;">Branch is
                                    required</span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <span class="input-label">User Access<span class="pl-1 text-danger">*</span></span>
                                <div class="d-flex align-items-center ml-4 mt-4">
                                    <div v-if="loggedInUser.user_role == 'owner'" class="d-flex align-items-center">
                                        <input type="radio" id="super_super_admin" v-model="user_access" name="user_super_admin" value="super_admin">
                                        <label class="ml-2 mb-0" for="super_super_admin" selected>Super Admin</label>
                                    </div>
                                    <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="d-flex align-items-center ml-6">
                                        <input type="radio" id="user_admin" v-model="user_access" name="user_admin" value="admin">
                                        <label class="ml-2 mb-0" for="user_admin">Admin </label><br>
                                    </div>
                                    <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'admin'" class="d-flex align-items-center ml-6">
                                        <input type="radio" id="user_user" v-model="user_access" name="user_user" value="employee">
                                        <label class="ml-2 mb-0" for="user_user">User</label><br>
                                    </div>
                                </div>
                                <span class=" text-danger" v-show="errors.has('user_access')" style="text-transform: none !important;">User Access
                                 are required</span>
                            </div>
                        </div>
                         <div class="mt-9 pb-8 mb-1">
                            <div class="d-flex align-items-center justify-content-center" v-if="basicInfo">
                                <button class="btn btn-outline-secondary" :disabled="basicInfo_loader" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button>
                                <button class="btn btn-new-secondary ml-5" :disabled="basicInfo_loader" style="width: 120px;border-radius: 5px;" @click="nextPermissionProduct">Next
                                    <hollow-dots-spinner v-if="basicInfo_loader" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--link-product-permission  v-if="comp_id" ></!--link-product-permission-->
        <!--create-success v-if="create_success" modal_name="create_success"></create-success-->
    </div>

</template>

<script>
    import { HollowDotsSpinner } from "epic-spinners";
    import DatePicker from 'vue2-datepicker'
    import companies from '../../mixins/companies';
    import organisation from '../../mixins/organization';
    import LinkProductPermission  from "./LinkProductPermission.vue";
    import {HalfCircleSpinner} from 'epic-spinners';
    import { EventBus } from '../../eventBus/nav-bar-event';
    export default {
        mixins: [companies,organisation],
        data() {
            return {
                subsidiary_emp_type:[],
                link_emp_type:[],
                comp_id: false,
                link_emp:'',
                subsidiary:'',
                organisation: '',
                organisation_type: [],
                branch: '',
                business_units: '',
                business_units_type: [],
                branch_type_options: [],
                department: '',
                department_type: [],
                branch_loading: false,
                organisation_loading:false,
                basicInfo: true,
                user_access: 'admin',
                permissionProduct:false,
                basicInformationTab: true,
                permissionProductTab: false,
                business_unit_loading: false,
                subsidiary_emp_loading: false,
                department_unit_loading: false,
                reporting_manager_loading: false,
                communicationInformationTab: true,
                link_emp_loading:false,
                loginInformationTab: true,
                isLoading: {
                    branch_type_options: false,
                    subsidiary_emp_type: false,
                    department_type: false,
                    organisation_type: false,
                    business_units_type: false,
                    link_emp_type:false,
                },
                skip: 0,
                limit: 10,
                search_key: "",
                ajax_call_in_progress:false,
                basicInfo_loader: false,
            }
        },
        methods: {
            //organisation change
            orgChange(){
                this.link_emp = '';
            },
            //reset data if compnay to link data cahnge
            dataChange(){
                this.subsidiary = '';
                this.department = '';
                this.branch = '';
                this.business_units = '';
            },
            //reset dept data if business unit's data change
            deptChange(){
                this.department = '';
            },
            //redirecting to the settings page
            /**@params = value */
            goToSettings(value) {
                this.$router.push('/settings/general');
            },
            //show/hide the basic information tab
            showBasicInformation () {
                this.basicInformationTab = !this.basicInformationTab;
            },
            //show/hide the communication information tab
            showCommunicationInformation () {
                this.communicationInformationTab = !this.communicationInformationTab;
            },
            //show/hide the basic information tab
            showLoginInformation() {
                this.loginInformationTab = !this.loginInformationTab;
            },
            //cancel the link employee creation and redirect ot the organisation list view
            cancel() {
                this.$router.push(`/organization/listview`);
            },
            //proceed tot the product permission page
            nextPermissionProduct() {
                this.basicInfo_loader = true
                this.$validator.validateAll().then(result => {
                    if (result) {
                        localStorage.setItem("companyId", JSON.stringify(this.link_emp.company_id))
                        localStorage.setItem("link_emp_data", JSON.stringify(this.create_data));
                        setTimeout(() => {
                        this.basicInfo_loader = false   
                        }, 10000);
                        this.$emit('switchWizard'); 
                    }
                })
            },  
            //Api call to get the branch names    
            async getBranchOptions() {
                this.branch_type_options = [];
                this.branch_loading = true;
                /**@params = skip, @params =limit, @params = company_id  */
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        company_id: this.link_emp.company_id
                    }
                    let response = await this.getListBranch(params.skip, params.limit, params.company_id);
                    if(response.status_id == 1) {
                        let branch_data = response.response;
                        //setting the id and name of the branch inot the branch data 
                        branch_data.forEach((branch, index) => {
                            this.branch_type_options.push({
                                id: branch.id,
                                name: branch.name
                            })
                        });
                    }
                    this.branch_loading = false;
                }
                catch(err) {
                }
            },
            //Api call to get the business unit options
            /**@params = key */
            async getBusinessUnitOptions(key) {
                this.business_units_type = [];
                this.business_unit_loading = true;
                try {
                    /**@params = skip, @params =limit, @params = company_id, @params = search_key  */
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: key  ||  '',
                        company_id: this.link_emp.company_id
                    }
                    let response = await this.getListBusinessUnit(params);
                    if(response.status_id == 1) {
                        let bus_data = response.response;
                        bus_data.forEach((business_unit, index) => {
                            this.business_units_type.push({
                                id: business_unit.id,
                                name: business_unit.name
                            })
                        });
                    }
                    this.business_unit_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            //Api call to get the department data
            /**@params = key */
            async getDepartmentUnitOptions(key) {
                this.department_type = [];
                this.department_unit_loading = true;
                try {
                    /**@params = skip, @params =limit, @params = company_id, @params = search_key,@params = company_id, @parmas = business_unit_id  */
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: key || '',
                        company_id: this.link_emp.company_id,
                        business_unit_id:this.business_units.id
                    }
                    let response = await this.getDepartment(params);
                    if(response.status_id == 1) {
                        let department_data = response.response;
                        department_data.forEach((department_unit, index) => {
                            this.department_type.push({
                                id: department_unit.id,
                                name: department_unit.name
                            })
                        });
                    }
                    this.department_unit_loading = false;
                    
                }
                catch(err) {
                    console.log(err);
                }
            },
            //Api call to get the organisation options
            async getOrganisationOptions(search) {
                this.organisation_type = [];
                this.organisation_loading = true;
                /**@params = skip, @params =limit, @params = search_key */
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                        search_key: search || ""
                    }
                    let response = await this.getHierarchyListView(params);
                    if(response.status_id == 1) {
                        this.organisation_type = response.response;
                        this.total = response.total_count;
                    }
                    this.organisation_loading = false;
                } catch(err) {
                    this.organisation_loading = false;
                }
            },
            //Api call to get the subsidiaay employee
            /**@params = key */
            async getSubsidiaryEmpOptions(key) {
                this.subsidiary_emp_type =[],
                this.subsidiary_emp_loading = true;
                /**@params = skip, @params =limit, @params = search_key, @params =organization_id, @params = company_id */
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: key || '',
                        organization_id: this.organisation.id,
                        company_id: this.link_emp.company_id 
                    }
                    let response = await this.getSubsidiaryEmpList(params);
                    if(response.status_id == 1) {
                        let subsidiary_data = response.response;
                        
                        subsidiary_data.forEach((subsidiary, index) => {
                            this.subsidiary_emp_type.push({
                                id: subsidiary.id,
                                name: subsidiary.full_name,
                                email: subsidiary.email,
                                company_name: subsidiary.company_name
                            })
                        });
                        this.subsidiary_emp_type = this.subsidiary_emp_type.filter((thing, index) => {
                            const payload = JSON.stringify(thing);
                            return index === this.subsidiary_emp_type.findIndex(obj => {
                                return JSON.stringify(obj) === payload;
                            });
                        });   
                    }
                    this.subsidiary_emp_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            //Api call to get the link employee data 
            async getLinkEmpOptions() {
                this.link_emp_type = [];
                this.link_emp_loading = true;
                /**@params = skip, @params =limit, @params = search_key, @params =organization_id */
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                        search_key: this.search_key,
                        organization_id: this.organisation.id
                    }
                    let response = await this.getHierarchyListView(params);
                    if(response.status_id == 1) {
                        let data = response.response
                        for(let i=0; i<data.length;i++){
                            if(data[i].id == this.organisation.id){
                                this.link_emp_type = data[i].company_list
                            }
                        }
                        this.total = response.total_count;
                    }
                    this.link_emp_loading = false;
                    
                }
                catch(err) {
                    console.log(err);
                }
            },
        },
        mounted() {
            //getting the company domain name from local storage
            this.company_domain_name = localStorage.getItem("company_domain_name");
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            create_data() {
                return {
                    organisation: this.organisation.id,
                    branch_id: this.branch.id,
                    user_access: this.user_access,
                    business_unit_id: this.business_units.id,
                    department_id: this.department.id,
                    company_id: this.link_emp.company_id,
                    department_id: this.department.id,
                    login_id: this.subsidiary.email,
                    products: []
                }

            }  
        },
        created() {
            const dict = {
                custom: {
                    
                }
            };
            this.$validator.localize("en", dict);       
        },
        components: {
            DatePicker,
            LinkProductPermission,
            HalfCircleSpinner,
            HollowDotsSpinner
        },
    }
</script>
<style>
    .pointer-disable{
        cursor: not-allowed !important;
    }
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .main-div {
        background: #fff;
        border-bottom-right-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 0 0 6px 0 !important;
    }

    .overflow-auto {
        overflow: auto;
    }

    .change-pic {
        position: absolute;
        bottom: -10px;
        width: 100%;
    }

    .edit-image {
        position: absolute;
        right: 0;
        bottom: 5px;
    }

    .change-cover-pic {
        padding: 3.5px 10px;
        border-radius: 50px;
        -webkit-backdrop-filter: blur(1.5px);
        backdrop-filter: blur(1.5px);
        background-color: rgba(114, 100, 100, 0.2);
        color: #fff;
    }

    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }

    .input-label {
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.39px;
        text-align: left;
        color: #0475f3;
        padding: 0 0 6px 10px;
    }

    .input-field {
        border-radius: 8px !important;
        border: solid 0.5px #3f3f3f;
        background-color: #fff;
        padding: 8px 10px;
        width: 100% !important;
        height: 40px;
    }

    .input-field-length {
        opacity: 0.5;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.3px;
        color: #000;
    }
    .multiselect--disabled {
		background: #ededed !important;
		pointer-events: none !important;;
		opacity: 1 !important;
	}

    .btn-new-cancel {
        color: #fff;
        background-color: #d51650;
        border-radius: 5px;
        padding-left: 35px;
        padding-right: 35px;
        text-transform: none;
        font-weight: 500;
        border: 2px solid #d51650;
        line-height: 30px;
        box-shadow: none;
    }

    .unfinishedCircle {
        background: #e8e8e8;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.32);
        font-weight: 500;
    }

    .finishedCircle {
        background: #128807;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .selectedCircle {
        background: #ff9a06;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .finishedCircleText {
        position: absolute;
        font-size: 14px;
        color: #303031;
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedCircleText {
        position: absolute;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.32);
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedDash {
        color: #e8e8e8;
        font-size: 20px;
        border-top: 2px dashed rgba(0, 0, 0, 0.32);
        width: 25%
    }

    .finishedDash {
        color: #128807;
        font-size: 20px;
        border: 1px dashed #128807;
        width: 100px;
    }

    .mx-input {
        display: inline-block;
        height: 0px !important;
    }
    .mx-input-wrapper{
        padding :8px 0px !important
    }
    .pointer-event {
        background-color: #fff !important;
        cursor : not-allowed !important;
    }
    @media only screen and (max-width: 768px){
        .bottom-padding{
            padding-bottom: 150px !important;
            margin-right: 5px !important;
        }
    }
</style>